.paginationBtns {
  width: 80%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  list-style: none;
  margin: auto;
  margin-top: 20px;
}

.paginationBtns a {
  color: #fff !important;
  padding: 2px 10px;
  background: #212245;
  border-radius: 5px;
  justify-content: center;
}
